import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { BASE_URL, authTokenInHeader } from "../../../utils/constant";
import PartsPreloader from "../../../components/SinglePartsPreloader/PartsPreloader";
import { Link } from "react-router-dom";

const MyAllParts = () => {
  const [myAllParts, setmyAllParts] = useState([]);
  console.log(myAllParts.filter(parts => parts.quoteRequest === true))
  const [loading, setloading] = useState(true);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  useEffect(() => {
    fetchAllParts();
  }, []);
  const fetchAllParts = async () => {
    const response = await axios.get(`${BASE_URL}/api/parts`, {
      headers: authTokenInHeader(),
    });
    setmyAllParts(response.data);
    setloading(false);
  };

  const formattedDimensions = (value) => {
    return `${(value).toFixed(3)}in`
  }

  return (
    <div className="container mt-4">
      <h2> Waiting for Quotes</h2>
      {myAllParts.length < 1 && !loading && (
        <p className="text-center"> No Parts Available ! </p>
      )}
      {loading ? (
        <>
          {[1, 2].map((el, id) => (
            <div className="mb-4" key={id}>
              <PartsPreloader />
            </div>
          ))}
        </>
      ) : (
        <>
          {myAllParts.filter(parts => parts.quoteRequest === true).map((parts, i) => (
            <Link
              className="p-4 mb-4 text-decoration-none"
              to={`/quotes/${parts._id}`}
            >
              <Card key={i}>
                <div className="d-flex" style={{ gap: "20px", padding: '20px' }}>
                  <div className="material_box">
                    <img src={`${BASE_URL}/uploads/${parts.materialFile}.png`} alt='part_img' />
                  </div>
                  <div style={{ width: "100%" }} className="single_parts">
                    <h2> {parts.serviceName ? parts.serviceName : 'Parts  Service'} </h2>
                    <p className="m-0 py-1">{parts.materialFile}</p>
                    <div
                      className="d-flex py-1"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> {new Date(parts.createdAt).toLocaleString('en-US', options)} </span>
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <span>Quantity {parts.materialQTY} </span>
                        {/* <button className="btn border-btn">
                          Configure Parts
                        </button> */}
                      </div>
                    </div>
                    <hr className="my-0" />
                    <p className="py-1">
                      <span className="mr-3"> X: {formattedDimensions(parts.dimention[0])} </span>
                      <span className="mr-3"> Y: {formattedDimensions(parts.dimention[1])} </span>
                      <span className="mr-3"> Z: {formattedDimensions(parts.dimention[2])} </span>
                    </p>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </>
      )}
    </div>
  );
};

export default MyAllParts;
