import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

export default function DeleteMaterialModal({
  title,
  materialDetails,
  deleteMaterial,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isCreating, setisCreating] = useState(false);

  const handleDeleteMaterial = async () => {
    setisCreating(true);
    await deleteMaterial(materialDetails._id)
    handleClose();
    setisCreating(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={handleOpen}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div >
              <h5 style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '-5px', marginBottom: '12px' }}> <DeleteIcon sx={{ color: red[500], marginRight: '8px' }} /> Delete Material </h5>
              <h6>Are you sure you want to delete this material?</h6>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
              <Button variant="outlined"
                className="bg_common_button mr-2"
                onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained"
                color="error" onClick={handleDeleteMaterial}>
                {isCreating ? 'Deleting ...' : 'Delete'}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
