import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { toast } from "react-hot-toast";
import axios from "axios";
import {
  Form,
} from "@themesberg/react-bootstrap";
import { BASE_URL, authTokenInHeader } from "../../../../utils/constant";
import { Key } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const initialAdminAccess = {
  canManageMaterial: false,
  canManageUsers: false,
  canManageSettings: false
};

const initialState = {
  // firstname: '',
  // lastname: '',
  // email: '',
  // company: '',
  // country: '',
  password: '',
  // phone: '',
  // postalCode: '',
  isAdmin: false,
  adminAccess: initialAdminAccess,
};

export default function EditUserModal({
  title,
  userDetails,
  reFetchMaterials,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isCreating, setisCreating] = useState(false);

  const [formData, setFormData] = useState(initialState);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'isAdmin') {
      setFormData({
        ...formData,
        [name]: !formData.isAdmin,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

  };

  const clearAllState = () => {
    setFormData(initialState);
  };

  const handleCheckboxChange = (key) => {
    setFormData({
      ...formData,
      adminAccess: {
        ...formData.adminAccess,
        [key]: !formData.adminAccess[key]
      },
    });
  };


  const editUser = async (e) => {
    e.preventDefault();
    if (isCreating) return;
    if (!formData.password || !formData.adminAccess) {
      toast.error("Please fill the form correctly and try again !!!");
    } else {
      setisCreating(true);
      try {
        const response = await axios.put(
          `${BASE_URL}/api/admin/users/${userDetails._id}`, formData,
          {
            headers: authTokenInHeader(),
          }
        );
        toast.success("User Updated successfully !!!");
        handleClose();
        clearAllState();
        reFetchMaterials();
      } catch (error) {
        console.error("Error updating user:", error);
      }
      setisCreating(false);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        className="bg_common_button mr-2"
        onClick={handleOpen}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h5>Edit User </h5>
            <hr />
            <form onSubmit={(e) => editUser(e)}>
              <div className="mb-4">
                <label>Password</label>
                <input
                  name='password'
                  onChange={handleFormChange}
                  value={formData.password}
                  required
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="mb-4">
                <Form.Check
                  type='checkbox'
                  id={`default-isAdmin`}
                  label='isAdmin'
                  name='isAdmin'
                  checked={formData.isAdmin}
                  onChange={handleFormChange}
                />
              </div>

              <div className="mb-4">
                <label>Admin Access</label>
                {Object.keys(userDetails.adminAccess).map(key =>
                  <Form.Check // prettier-ignore
                    key={`default-${key}`}
                    type='checkbox'
                    id={`default-${key}`}
                    label={key}
                    checked={formData.adminAccess[key]}
                    onChange={() => handleCheckboxChange(key)}
                  />
                )}
              </div>

              {isCreating ? (
                <Button type="submit" variant="outlined">
                  Updating ...
                </Button>
              ) : (
                <Button type="submit" variant="contained" className="bg_common_button">
                  Update
                </Button>
              )}
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}
