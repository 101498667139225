import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { toast } from "react-hot-toast";
import {
  Form,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { BASE_URL, authTokenInHeader } from "../../../../utils/constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "scroll",
};

const initialAdminAccess = {
  canManageMaterial: false,
  canManageUsers: false,
  canManageSettings: false
};

const properties = ['firstname', 'lastname', 'email', 'company', 'country', 'password', 'phone', 'postalCode',];

const subscriptionOptions = ['free', 'basic', 'premium'];

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  country: '',
  password: '',
  phone: '',
  postalCode: '',
  subscription: { plan: subscriptionOptions[0] },
  isAdmin: false,
  adminAccess: initialAdminAccess,
};

export default function AddNewUserModal({ title, reFetchUsers }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isCreating, setisCreating] = useState(false);

  const [formData, setFormData] = useState(initialState);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'isAdmin') {
      setFormData({
        ...formData,
        [name]: !formData.isAdmin,
      });
    } else if (name === 'subscription') {
      setFormData({
        ...formData,
        [name]: { plan: e.target.value },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  console.log(formData)

  const clearAllState = () => {
    setFormData(initialState);
  };

  const handleCheckboxChange = (key) => {
    setFormData({
      ...formData,
      adminAccess: {
        ...formData.adminAccess,
        [key]: !formData.adminAccess[key]
      },
    });
  };

  const addUser = async (e) => {
    e.preventDefault();
    if (isCreating) return;
    if (!formData.firstname || !formData.lastname || !formData.email) {
      toast.error("Please fill the form correctly and try again !!!");
    } else {
      setisCreating(true);
      try {
        const response = await axios.post(
          `${BASE_URL}/api/admin/users`,
          formData,
          {
            headers: authTokenInHeader(),
          }
        );
        console.log(response)
        toast.success("User added successfully !!!");
        handleClose()
        clearAllState();

      } catch (error) {
        console.log(error);
      }
      setisCreating(false);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        className="bg_common_button"
        onClick={handleOpen}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h5>Add New User </h5>
            <hr />
            <form onSubmit={(e) => addUser(e)}>
              {properties.map((property, index) => (
                <div className="mb-4" key={index}>
                  <label style={{ textTransform: "capitalize" }}>{property}</label>
                  <input
                    name={property}
                    onChange={handleFormChange}
                    value={formData[property]} // Dynamically access state based on property name
                    required
                    type="text"
                    className="form-control"
                  />
                </div>
              ))}

              <div className="mb-4">
                <label>Subscription Plan:</label>
                <select
                  name="subscription"
                  value={formData.subscription.plan}
                  onChange={handleFormChange}
                  className="form-control"
                >
                  {subscriptionOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <Form.Check
                  type='checkbox'
                  id={`default-isAdmin`}
                  label='isAdmin'
                  name='isAdmin'
                  checked={formData.isAdmin}
                  onChange={handleFormChange}
                />
              </div>

              <div className="mb-4">
                <label>Admin Access</label>
                {Object.keys(formData.adminAccess).map(key =>
                  <Form.Check
                    key={`default-${key}`}
                    type='checkbox'
                    id={`default-${key}`}
                    label={key}
                    checked={formData.adminAccess[key]}
                    onChange={() => handleCheckboxChange(key)}
                  />
                )}
              </div>

              {isCreating ? (
                <Button type="submit" variant="outlined">
                  Creating ...
                </Button>
              ) : (
                <Button type="submit" variant="outlined">
                  Create
                </Button>
              )}
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}
