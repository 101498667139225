import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import EditUserModal from "./EditUserModal";
import {
  Dropdown,
  Nav,
  Spinner
} from "@themesberg/react-bootstrap";
import DeleteUserModal from "./DeleteUserModal";

export default function UserTable({
  userList,
  deleteUser,
  fetchUser,
  loading
}) {
  return (
    <TableContainer component={Paper}>
      <div className="p-3">
        <h4> User List </h4>
      </div>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" responsive="xl">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">
              Email
            </TableCell>
            {/* <TableCell align="center"> Password </TableCell> */}
            <TableCell align="center"> Phone</TableCell>
            <TableCell align="center"> Subscription </TableCell>
            <TableCell align="center"> IsAdmin </TableCell>
            <TableCell align="center"> AdminAccess </TableCell>
            <TableCell align="center"> Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ?
            <TableRow>
              <TableCell align="center">{ }</TableCell>
              <TableCell align="center">{ }</TableCell>
              <TableCell align="center">{ }</TableCell>
              <TableCell className="m-4" align="center"><Spinner as="span" animation="border" center /></TableCell>
            </TableRow>
            : userList.map((row, id) => (
              <TableRow
                key={id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                  {row.firstname + " " + row.lastname}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                {/* <TableCell align="center">{row.password}</TableCell> */}
                <TableCell align="center">{row.phone}</TableCell>
                <TableCell align="center">{row.subscription.plan}</TableCell>
                <TableCell align="center">{row.isAdmin ? "Yes" : "No"}</TableCell>
                <TableCell align="center">
                  {/* {row.adminAccess.canManageMaterial} */}
                  <Dropdown>
                    <Dropdown.Toggle as={Nav.Link} id="dropdown-basic">
                      <div>
                        <Button variant="contained" className="bg_common_button mr-2">
                          Admin Access
                        </Button>
                      </div>

                    </Dropdown.Toggle>

                    <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                      {Object.keys(row.adminAccess).map((key) => (
                        <Dropdown.Item style={{ textDecoration: "none" }}>
                          {key}: {row.adminAccess[key] ? 'true' : 'false'}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </TableCell>

                <TableCell align="center" style={{ minWidth: '200px' }}>
                  <EditUserModal userDetails={row} reFetchMaterials={fetchUser} title={"Edit"} />
                  <DeleteUserModal userDetails={row} deleteUser={deleteUser} title={"Delete"} />
                </TableCell>
              </TableRow>
            ))}
          {userList.length < 1 && !loading && (
            <td colSpan="4">
              <h3 className="m-4 text-center"> No Record Finded </h3>
            </td>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
