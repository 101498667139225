import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import filepath from './Cube_3d_printing_sample.stl';

import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '100%',
  height: '100%',
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  zIndex: 9999,
};

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: '20px 20px 0 20px',
  zIndex: 9999
};


export default function ThreeDModal({ title, url }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isCreating, setisCreating] = useState(false);


  useEffect(() => {

    let scene, camera, renderer, container;
    container = open && document.querySelector('.three-container');

    var element = document.querySelector('.three-d-card');
    var width = element.offsetWidth;

    init();

    function init() {

      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(4, 2, 4);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xffffff);
      // scene.fog = new THREE.Fog(0xa0a0a0, 4, 20);


      //

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 3);
      hemiLight.position.set(0, 20, 0);
      scene.add(hemiLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(0, 20, 10);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 2;
      directionalLight.shadow.camera.bottom = - 2;
      directionalLight.shadow.camera.left = - 2;
      directionalLight.shadow.camera.right = 2;
      scene.add(directionalLight);

      // ground

      const ground = new THREE.Mesh(new THREE.PlaneGeometry(40, 40), new THREE.MeshPhongMaterial({ color: 0xbbbbbb, depthWrite: false }));
      ground.rotation.x = - Math.PI / 2;
      ground.receiveShadow = true;
      scene.add(ground);

      const grid = new THREE.GridHelper(40, 20, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      scene.add(grid);

      // export mesh

      const loader = new STLLoader().load(url, async function (geometry) {
        // const material = new THREE.MeshLambertMaterial({ color: 0x00ff00 });
        const material = new THREE.MeshLambertMaterial({ color: 0x48dbfb })
        const object = new THREE.Mesh(geometry, material);

        object.scale.set(0.08, 0.08, 0.08);
        object.position.set(5, -3, 1);
        object.castShadow = true;
        object.receiveShadow = true;
        scene.add(object);
      }, (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
        (error) => {
          console.log(error)
        });

      //
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth - 150, window.innerHeight - 150);
      renderer.shadowMap.enabled = true;
      // document.body.appendChild(renderer.domElement);
      open && container.appendChild(renderer.domElement);
      //

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0.1, 0);
      controls.update();

      //

      window.addEventListener('resize', onWindowResize);

      // const gui = new GUI();

      // gui.add(params, 'exportASCII').name('Export STL (ASCII)');
      // gui.add(params, 'exportBinary').name('Export STL (Binary)');
      // gui.open();
      animate();
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

  }, [open, url])

  return (
    <>
      <Button variant="outlined"
        className="bg_common_button my-2"
        onClick={handleOpen}>
        {title}
      </Button>

      {
        open ? <div style={style} >
          <Box sx={boxstyle}>
            <div className="three-container" style={{ width: '100%', height: '100%' }} ></div>
            <Button variant="outlined"
              className="bg_common_button my-2"
              onClick={handleClose}>
              Cancel
            </Button>
          </Box>

        </div> : ''
      }
    </>
  );
}


