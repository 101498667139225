import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import AddNewUserModal from "./AddNewUserModal";
import { AiOutlinePlus } from "react-icons/ai";

import axios from "axios";
import { BASE_URL, authTokenInHeader } from "../../../../utils/constant";
import toast from "react-hot-toast";

const UserManagement = () => {

  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUsers()
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${BASE_URL}/api/users`, {
        headers: authTokenInHeader(),
      });
      setUserList(response.data)
      setLoading(false)
    } catch (error) {
      console.log("error", error);
      setLoading(false)
    }
  };

  console.log(userList)

  const deleteUser = async (userId) => {
    console.log("deleteUsers", userId)
    try {
      const response = await axios.delete(`${BASE_URL}/api/users/${userId}`, {
        headers: authTokenInHeader(),
      });
      toast.success("User deleted successfully")
      fetchUsers()
    } catch (error) {
      console.log("error", error);
    }
  };


  return (
    <div className="container">
      <div className="mb-3">
        <AddNewUserModal
          title={
            <span>
              <AiOutlinePlus /> Add New User
            </span>
          }
          reFetchUsers={fetchUsers}
        />
      </div>
      <UserTable userList={userList} deleteUser={deleteUser} fetchUser={fetchUsers} loading={loading} />
    </div>
  );
};

export default UserManagement;
